import React from 'react';

const DescriptionBlock = () => {

  const array = [
    {
      name: 'Базовые модули',
      data: [
        {
          header: 'Слот-координация',
          text: [
            'Автоматизированное согласование слотов между службами',
            'Контроль и управление альтернативами',
            'Соответствие требованиям IATA и российским федеральным стандартам'
          ]
        },
        {
          header: 'Расписание движения ВС',
          text: [
            'Планирование расписания на сезон',
            'Импорт и экспорт расписания в различных форматах',
            'Автоматическое формирование телеграмм'
          ]
        },
        {
          header: 'Суточный план полётов',
          text: [
            'Оперативное управление и мониторинг выполнения плана',
            'Автоматическое наполнение плана на основе входящих телеграмм',
            'Архивирование данных для последующего анализа'
          ]
        },
        {
          header: 'Контроль наземного обслуживания',
          text: [
            'Редактор шаблонов технологических графиков обслуживания',
            'Оперативный контроль выполнения операций',
            'Интеграция с модулем "Мобильные рабочие места'
          ]
        },
        {
          header: 'Администрирование',
          text: [
            'Управление пользователями и разграничение доступа',
            'Настройка параметров системы',
            'Контроль серверной нагрузки'
          ]
        },
        {
          header: 'Шина данных',
          text: [
            'Обеспечение интеграции с различными информационными системами',
            'Экспорт и импорт информации в стандартных форматах',
            'Гибкие инструменты настройки интеграции'
          ]
        },
        {
          header: 'Центр сообщений',
          text: [
            'Обработка сообщений по различным каналам связи (АФТН, SITATEX, EMAIL)',
            'Автоматическое формирование и отправка авиационных сообщений',
            'Ведение архива телеграмм'
          ]
        }
      ]
    },
    {
      name: 'Информирование пассажиров',
      data: [
        {
          header: 'Визинформ',
          text: [
            'Создание шаблонов табло с помощью графического конструктора',
            'Гибкая настройка отображения информации',
            'Поддержка многоязычного интерфейса'
          ]
        },
        {
          header: 'Телеграм-бот',
          text: [
            'Информирование пассажиров о расписании и статусе рейсов',
            'Оповещение сотрудников о назначенных работах',
            'Снижение нагрузки на справочную службу аэропорта'
          ]
        },
        {
          header: 'Диктор',
          text: [
            'Автоматизированное формирование очереди звуковых сообщений',
            'Поддержка различных синтезаторов речи и языков озвучивания',
            'Гибкая настройка зон озвучивания'
          ]
        }
      ]
    },
    {
      name: 'Обслуживание пассажиров и багажа',
      data: [
        {
          header: 'Регистрация пассажиров и багажа',
          text: [
            'Поддержка веб-регистрации и киосков самообслуживания',
            'Многофункциональный редактор компоновок воздушных судов',
            'Возможность одновременной регистрации на несколько рейсов'
          ]
        },
        {
          header: 'Контроль досмотра пассажиров и багажа',
          text: [
            'Интеграция с системами регистрации',
            'Контроль прохождения пассажирами всех этапов предполетных процедур',
            'Предотвращение несанкционированного прохода между зонами аэропорта'
          ]
        },
        {
          header: 'Контроль погрузки багажа',
          text: [
            'Контроль движения багажа при терминальной и перронной обработке',
            'Минимизация потерь багажа и исключение ошибок обработки',
            'Оперативный поиск багажа на любом этапе обработки'
          ]
        },
        {
          header: 'Мониторинг трансферных стыковок',
          text: [
            'Автоматическое поступление данных о трансферных пассажирах и багаже',
            'Оперативное управление трансферными потоками',
            'Предотвращение потерь стыковок в штатных и сбойных ситуациях'
          ]
        },
        {
          header: 'Учёт неисправного и нерегулярного багажа',
          text: [
            'Ведение электронного архива актов о неисправностях',
            'Формирование статистических и аналитических отчетов',
            'Веб-интерфейс для быстрого приема сообщений от пассажиров'
          ]
        }
      ]
    },
    {
      name: 'Экономика и учет',
      data: [
        {
          header: 'Сборы',
          text: [
            'Автоматический расчет сборов за наземное обслуживание',
            'Гибкая система настройки тарифов и скидок',
            'Интеграция с бухгалтерскими системами'
          ]
        },
        {
          header: 'Аналитическая отчётность',
          text: [
            'Конструктор плоских и сводных отчетов',
            'Формирование мульти-отчетов по нескольким аэропортам',
            'Глубокий анализ деятельности аэропорта по различным показателям'
          ]
        },
        {
          header: 'Рабочий стол руководителя',
          text: [
            'Оперативное информирование о ходе выполнения производственных процессов',
            'Адаптация для мобильных устройств',
            'Доступ к ключевым показателям в режиме реального времени'
          ]
        }
      ]
    },
    {
      name: 'Управление ресурсами',
      data: [
        {
          header: 'Терминал',
          text: [
            'Планирование и управление статическими ресурсами',
            'Долгосрочное планирование и оперативное управление',
            'Возможность создания нескольких планов распределения ресурсов'
          ]
        },
        {
          header: 'Перрон',
          text: [
            'Графическое представление информации о занятости мест стоянок',
            'Учет перебуксировок и перемещений воздушных судов',
            'Гибкий редактор мест стоянок на карте перрона'
          ]
        },
        {
          header: 'Управление динамическими ресурсами',
          text: [
            'Планирование работы персонала и техники',
            'Автоматизированное распределение работ',
            'Оповещение сотрудников о план-наряде на смены'
          ]
        },
        {
          header: 'Мобильные рабочие места',
          text: [
            'Оперативное внесение данных по выполненным операциям',
            'Формирование электронных чек-листов',
            'Работа в офлайн-режиме'
          ]
        },
        {
          header: 'Мониторинг исполнителей 2D и 3D',
          text: [
            'Контроль перемещения персонала и техники на перроне',
            'Отображение данных на 2D и 3D картах',
            'Своевременное предупреждение о возможных сбоях в обслуживании'
          ]
        },
        {
          header: 'КУРС',
          text: [
            'Учет и управление автотранспортным комплексом',
            'Планирование и контроль технического обслуживания',
            'Учет горюче-смазочных материалов'
          ]
        }
      ]
    },
    {
      name: 'Опциональные модули',
      data: [
        {
          header: 'A-CDM (портал совместного принятия решений)',
          text: [
            'Удаленный доступ к оперативно-производственной информации',
            'Многоязычный интерфейс',
            'Соответствие методикам Eurocontrol'
          ]
        },
        {
          header: 'Грузовой терминал',
          text: [
            'Автоматизация работы грузового терминала/склада',
            'Обработка сообщений Cargo IMP и Cargo XML',
            'Формирование необходимой полетной и аналитической документации'
          ]
        },
        {
          header: 'Голосовой бот',
          text: [
            'Автоматизированное ведение диалога с пассажирами',
            'Снижение нагрузки на колл-центр',
            'Увеличение скорости обработки запросов'
          ]
        }
      ]
    }
  ]

  return (
    <div className="flex flex-col w-11/12 sm:w-10/12 mx-auto pt-4 pb-16 sm:py-24">
      <div className="flex flex-wrap items-center text-sm text-gst-main">
        <a href="/" className="flex items-center mr-2">Главная</a>
        <p className="text-xl mr-2">›</p>
        <a href="/cobra" className="flex mr-2">Кобра – автоматизация аэропорта</a>
        <p className="text-xl mr-2">›</p>
        <a href="/cobra/modules" className="flex mr-2">Описание основных модулей</a>
        <p className="text-xl mr-2 opacity-50 text-gst-text">›</p>
        <p className="flex items-center opacity-50 text-gst-text">Подробнее</p>
      </div>
        {array.map(item => (
          <div key={item.name} className="mt-8 sm:mt-12">
            <p className="text-4xl sm:text-gst64 leading-gst48 sm:leading-gst70 font-bold">{item.name}</p>
            <div className="mt-8 sm:mt-12 grid grid-cols-1 sm:grid-cols-2 gap-8">
              {item.data.map(i => (
                <div key={i.header} className="p-4 sm:p-8 flex flex-col rounded-gst20 border shadow-[0_6px_12px_0_#14142B0A]">
                  <p className="text-xl sm:text-2xl font-medium sm:font-semibold">{i.header}</p>
                  <ul className="mt-4 list-disc list-outside pl-5">
                    {i.text.map(text => (
                      <li key={text} className="sm:text-lg first:mt-0 mt-2">{text}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ))}




    </div>
  );
};

export default DescriptionBlock;