import React from 'react';
import CobraBlock from "./ui/CobraBlock";
import DiscussBlock from "../../components/DiscussBlock";
import CheckboxBlock from "../../components/CheckboxBlock";
import GridBlock from "../../components/GridBlock";
import MainSectionBlock from "../../components/MainSectionBlock";

const Index = () => {

  // const dataMain = {
  //   name: 'Продукты Аксиома',
  //   header: 'Управление техническим обслуживанием и ремонтами (ТОИР)',
  //   text: 'Аксиома: электроэнергетика - это отечественная система управления техническим обслуживанием и ремонтами (ТОиР) для электроэнергетической отрасли. Платформа направлена на цифровизацию процессов ТОиР, снижение стоимости владения активами и повышение надежности и безопасности энергетического оборудования.',
  //   icon: <AksiomaElectricIcon/>
  // }

  const dataKeyAdvantages = {
    name: 'Ключевые особенности системы Кобра',
    data: [
      {
        header: 'Централизованная база данных',
        text: 'Система объединяет все данные аэропорта в единую базу, обеспечивая целостность и актуальность информации для всех подразделений.'
      },
      {
        header: 'Полная управленческая информация',
        text: 'Кобра предоставляет руководству аэропорта всю необходимую информацию для принятия оперативных и стратегических решений.'
      },
      {
        header: 'Эффективное взаимодействие',
        text: 'Система улучшает коммуникацию между различными службами аэропорта, а также с партнерами и клиентами.'
      },
      {
        header: 'Адаптивность',
        text: 'Кобра легко адаптируется к изменениям в операционной среде аэропорта, обеспечивая гибкость бизнес-процессов.'
      },
      {
        header: 'Повышение конкурентоспособности',
        text: 'Внедрение системы значительно повышает эффективность управления авиапредприятием, что ведет к улучшению его позиций на рынке.'
      }
    ]
  }

  const dataTasks = {
    name: 'Внедрение и поддержка',
    data: [
      'Предпроектное обследование: анализ процессов аэропорта и планирование внедрения.',
      'Установка и настройка: развертывание системы под специфику аэропорта.',
      'Обучение персонала: тренинги для всех уровней сотрудников.',
      'Техподдержка 24/7: круглосуточное решение вопросов пользователей.',
      'Регулярные обновления: совершенствование системы с учетом отраслевых изменений и пожеланий клиентов.'
    ]
  }

  const dataResult = {
    name: 'Преимущества внедрения системы',
    data: [
      {
        header: 'Повышение эффективности управления',
        text: 'Централизация данных и автоматизация процессов позволяют руководству аэропорта принимать более взвешенные и своевременные решения.'
      },
      {
        header: 'Оптимизация использования ресурсов',
        text: 'Система обеспечивает эффективное распределение как статических, так и динамических ресурсов аэропорта.'
      },
      {
        header: 'Улучшение качества обслуживания пассажиров',
        text: 'Автоматизация процессов регистрации, информирования и обработки багажа значительно повышает удовлетворенность пассажиров.'
      },
      {
        header: 'Сокращение времени обслуживания воздушных судов',
        text: 'Оптимизация процессов наземного обслуживания позволяет сократить время пребывания самолетов на земле.'
      },
      {
        header: 'Повышение прозрачности процессов',
        text: 'Система обеспечивает полный контроль и мониторинг всех операций в аэропорту.'
      },
      {
        header: 'Адаптивность к изменениям',
        text: 'Модульная структура и гибкость настроек позволяют быстро адаптировать систему к меняющимся условиям работы аэропорта.'
      },
      {
        header: 'Интеграция с другими системами',
        text: 'Кобра легко интегрируется с различными информационными системами, обеспечивая единое информационное пространство.'
      }
    ]
  }

  return (
    <>
      {/*<MainSectionBlock data={dataMain}/>*/}
      <CobraBlock/>
      <GridBlock data={dataKeyAdvantages}/>
      <GridBlock data={dataResult} theme={'color'} size={'sm'}/>
      <CheckboxBlock data={dataTasks}/>
      <DiscussBlock/>
    </>
  );
};

export default Index;