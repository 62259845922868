import React from 'react';
import DiscussBlock from "../../components/DiscussBlock";
import DescriptionBlock from "./ui/DescriptionBlock";

const Index = () => {
  return (
    <>
      <DescriptionBlock/>
      <DiscussBlock/>
    </>
  );
};

export default Index;