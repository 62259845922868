import React from 'react';
import ModulesBlock from "./ui/ModulesBlock";
import DiscussBlock from "../../components/DiscussBlock";

const Index = () => {
  return (
    <>
      <ModulesBlock/>
      <DiscussBlock/>
    </>
  );
};

export default Index;