import React from 'react';
import MapBlock from "./ui/MapBlock";
import InfoBlock from "./ui/InfoBlock";

const Index = () => {
  return (
    <>
      <MapBlock/>
      <InfoBlock/>
    </>
  );
};

export default Index;