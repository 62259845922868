import React from 'react';
import ItBlock from "../Aksioma/ui/ItBlock";
import ProductsBlock from "../Aksioma/ui/ProductsBlock";
import DiscussBlock from "../../components/DiscussBlock";
import MainSectionBlock from "../../components/MainSectionBlock";
import {AksiomaEstateIcon} from "../../assets/icons";
import CheckboxBlock from "../../components/CheckboxBlock";
import GridBlock from "../../components/GridBlock";

const Index = () => {

  const dataMain = {
    name: 'Аксиома: имущество',
    header: 'Управление имуществом компании',
    text: 'АКСИОМА: имущество - это комплексное решение для управления эксплуатацией и обслуживанием объектов недвижимого имущества компании. Система направлена на повышение эффективности использования имущества и оптимизацию затрат на его эксплуатацию.',
    icon: <AksiomaEstateIcon/>
  }

  const dataKeyAdvantages = {
    name: 'Ключевые преимущества внедрения',
    data: [
      {
        header: 'Комплексный учет',
        text: 'Формирование полной и достоверной информации обо всех объектах недвижимости'
      },
      {
        header: 'Нормативное соответствие',
        text: 'Соблюдение государственных актов и требований по экологии и безопасности'
      },
      {
        header: 'Оперативное планирование',
        text: 'Эффективное планирование работ, ресурсов и бюджета'
      },
      {
        header: 'Информационная интеграция',
        text: 'Единая среда для финансового планирования, управления строительством и хозяйственного управления'
      },
      {
        header: 'Аналитическая поддержка',
        text: 'Подготовка информации для принятия решений по эффективному использованию имущества'
      },
    ]
  }

  const dataTasks = {
    name: 'Решаемые задачи',
    data: [
      'Ведение реестра объектов недвижимого имущества',
      'Планирование работ по капитальному строительству, ремонту и обслуживанию',
      'Управление материально-техническим снабжением',
      'Оценка эффективности использования имущественных комплексов',
      'Обеспечение многоаспектной аналитической информацией',
      'Интеграция данных для различных подразделений и целей'
    ]
  }

  const dataResult = {
    name: 'Результаты внедрения',
    data: [
      {header: 'Улучшение информационного обеспечения'},
      {header: 'Предоставление актуальной информации о состоянии основных средств'},
      {header: 'Доступ к данным о потребностях в закупках и трудозатратах'},
      {header: 'Оптимизация финансового планирования'},
      {header: 'Эффективное определение затрат на обслуживание активов'},
      {header: 'Создание банка технологической и финансовой аналитической информации'},
      {header: 'Повышение эффективности работы'},
      {header: 'Улучшение взаимодействия структурных подразделений'},
      {header: 'Сокращение времени на планирование и выполнение ремонтных работ'},
      {header: 'Экономическая эффективность'},
      {header: 'Снижение затрат на ремонтные работы и обслуживание основных средств'},
      {header: 'Уменьшение числа аварийных и внеплановых работ'},
      {header: 'Гибкость в формировании отчетности'},
      {header: 'Подготовка оперативных отчетов по различным группам объектов'},
      {header: 'Многоаспектный анализ данных в единой интегрированной среде}'}
    ]
  }

  return (
    <>
      <MainSectionBlock data={dataMain}/>
      <GridBlock data={dataKeyAdvantages}/>
      <ItBlock/>
      <CheckboxBlock data={dataTasks}/>
      <GridBlock data={dataResult} className={'h-32 sm:h-auto justify-center sm:justify-start'} theme={'color'} size={'sm'}/>
      <ProductsBlock/>
      <DiscussBlock/>
    </>
  );
};

export default Index;